import React from 'react';

function CancellationPolicy() {
    return (
        <div className="m-10 leading-relaxed">
            <h1 className="text-2xl font-semibold mb-5" id="cancellation-policy">Cancellation Policy</h1>
            <p><strong>The subscription can get canceled if you are not satisfied with the software. To cancel, you need to mail us at support@pickmystylist.com.</strong></p>
            <p>On the contrarily, you can tell us about the issues you are facing. We will try to amend the changes according to your requirements.</p>
            <p>The features can be integrated accordingly. After the completion of the cancellation policy, you do not need to pay us further.</p>
        </div>
    );
}

export default CancellationPolicy;